import React, { useState, useMemo, useRef, useEffect } from "react";

const MultiFileUploadSPA = () => {
  const [files, setFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState({});
  const [uploadProgress, setUploadProgress] = useState({});
  const [highlightedFiles, setHighlightedFiles] = useState({});
  const fileInputRef = useRef(null);

  const { fileCount, formattedTotalSize } = useMemo(() => {
    const count = files.length;
    const totalSize = files.reduce((total, file) => total + file.file.size, 0);
    const sizeInMB = totalSize / (1024 * 1024);
    const formatted =
      sizeInMB >= 1000
        ? `${(sizeInMB / 1024).toFixed(2)} GB`
        : `${sizeInMB.toFixed(2)} MB`;
    return { fileCount: count, formattedTotalSize: formatted };
  }, [files]);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const updatedFiles = [...files];
    const newHighlightedFiles = {};

    newFiles.forEach((newFile) => {
      const existingFileIndex = files.findIndex(
        (f) => f.file.name === newFile.name && f.file.size === newFile.size
      );
      if (existingFileIndex !== -1) {
        newHighlightedFiles[existingFileIndex] = true;
      } else {
        updatedFiles.push({
          file: newFile,
          status: "pending",
          progress: 0,
        });
      }
    });

    setFiles(updatedFiles);
    setHighlightedFiles(newHighlightedFiles);
  };

  useEffect(() => {
    if (Object.keys(highlightedFiles).length > 0) {
      const timer = setTimeout(() => {
        setHighlightedFiles({});
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [highlightedFiles]);

  const uploadFile = async (fileInfo, index) => {
    const { file } = fileInfo;
    setUploadStatus((prev) => ({ ...prev, [index]: "Initiating upload..." }));
    setUploadProgress((prev) => ({ ...prev, [index]: 0 }));

    try {
      const BASE_CF_URL = "https://r2upload.wprzylecki.workers.dev";
      const bucketName = "wpr-game";
      const key = `${file.name}`;
      const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
      const totalParts = Math.ceil(file.size / CHUNK_SIZE);

      console.log("Initiating upload:", {
        fileName: file.name,
        fileSize: file.size,
        totalParts,
      });

      const getMutltiPartUploadUrl = new URL(
        `${BASE_CF_URL}/getMultiPartUpload`
      );
      getMutltiPartUploadUrl.searchParams.append("bucket", bucketName);
      getMutltiPartUploadUrl.searchParams.append("key", key);

      console.log("Sending request to:", getMutltiPartUploadUrl.toString());

      let uploadIdResponse;
      try {
        uploadIdResponse = await fetch(getMutltiPartUploadUrl, {
          method: "GET",
          mode: "cors",
          credentials: "omit",
          headers: {
            Accept: "application/json",
          },
        });
      } catch (fetchError) {
        console.error("Fetch error during initiation:", fetchError);
        throw new Error(
          `Network error during upload initiation: ${fetchError.message}`
        );
      }

      if (!uploadIdResponse.ok) {
        console.error("Response status:", uploadIdResponse.status);
        console.error("Response status text:", uploadIdResponse.statusText);
        const errorText = await uploadIdResponse.text();
        console.error("Error response during initiation:", errorText);
        throw new Error(
          `Failed to initiate upload: ${uploadIdResponse.status} ${uploadIdResponse.statusText}`
        );
      }

      const multiPartUploadJson = await uploadIdResponse.json();
      console.log("Upload initiation response:", multiPartUploadJson);
      const uploadId = multiPartUploadJson.response.UploadId;

      console.log("Upload initiated:", { uploadId });

      const partsData = [];
      const uploadPartUrl = new URL(`${BASE_CF_URL}/uploadPart`);
      uploadPartUrl.searchParams.append("bucket", bucketName);
      uploadPartUrl.searchParams.append("key", key);
      uploadPartUrl.searchParams.append("uploadId", uploadId);

      for (let i = 0; i < totalParts; i++) {
        const start = CHUNK_SIZE * i;
        const end = Math.min(file.size, start + CHUNK_SIZE);
        const blob = file.slice(start, end);
        const partNumber = i + 1;
        const formData = new FormData();
        formData.append("file", blob);
        uploadPartUrl.searchParams.set("partNumber", partNumber);

        const uploadPartResponse = await fetch(uploadPartUrl, {
          method: "POST",
          body: formData,
        });

        if (!uploadPartResponse.ok) {
          throw new Error(
            `Failed to upload part ${partNumber}: ${uploadPartResponse.statusText}`
          );
        }

        const uploadPartJson = await uploadPartResponse.json();
        const eTag = uploadPartJson.response.ETag;
        partsData.push({ PartNumber: partNumber, ETag: eTag });

        const progress = Math.round((partNumber / totalParts) * 100);
        setUploadProgress((prev) => ({ ...prev, [index]: progress }));
        setUploadStatus((prev) => ({
          ...prev,
          [index]: `Uploaded part ${partNumber}/${totalParts}`,
        }));

        console.log(`Uploaded part ${partNumber}/${totalParts}:`, {
          eTag,
          progress,
        });
      }

      setUploadStatus((prev) => ({ ...prev, [index]: "Finalizing upload..." }));
      const completeUploadUrl = new URL(
        `${BASE_CF_URL}/completeMultipartUpload`
      );
      completeUploadUrl.searchParams.append("bucket", bucketName);
      completeUploadUrl.searchParams.append("key", key);
      completeUploadUrl.searchParams.append("uploadId", uploadId);

      const completeUploadResponse = await fetch(completeUploadUrl, {
        method: "POST",
        body: JSON.stringify({ parts: partsData }),
      });

      if (!completeUploadResponse.ok) {
        throw new Error(
          `Failed to complete upload: ${completeUploadResponse.statusText}`
        );
      }

      const completeUploadJson = await completeUploadResponse.json();
      console.log("Upload completed:", completeUploadJson);

      setUploadStatus((prev) => ({
        ...prev,
        [index]: "File uploaded successfully!",
      }));
      setUploadProgress((prev) => ({ ...prev, [index]: 100 }));
      setFiles((prev) =>
        prev.map((f, i) => (i === index ? { ...f, status: "uploaded" } : f))
      );
    } catch (error) {
      console.error("Detailed upload error:", error);
      setUploadStatus((prev) => ({
        ...prev,
        [index]: `An error occurred: ${error.message}`,
      }));
      setUploadProgress((prev) => ({ ...prev, [index]: 0 }));
      setFiles((prev) =>
        prev.map((f, i) => (i === index ? { ...f, status: "error" } : f))
      );
    }
  };

  const uploadAllFiles = () => {
    files.forEach((fileInfo, index) => {
      if (fileInfo.status === "pending") {
        uploadFile(fileInfo, index);
      }
    });
  };

  const clearAllFiles = () => {
    setFiles([]);
    setUploadStatus({});
    setUploadProgress({});
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setUploadStatus((prevStatus) => {
      const newStatus = { ...prevStatus };
      delete newStatus[index];
      return newStatus;
    });
    setUploadProgress((prevProgress) => {
      const newProgress = { ...prevProgress };
      delete newProgress[index];
      return newProgress;
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-2xl">
        <h1 className="text-2xl font-bold mb-4 text-center">
          Multi-File Upload SPA
        </h1>
        <div className="mb-4">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            multiple
            className="hidden"
          />
          <button
            onClick={handleFileSelect}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
          >
            Select Files
          </button>
        </div>
        {files.length > 0 && (
          <div className="mb-4 text-sm text-gray-600">
            <p>Liczba plików: {fileCount}</p>
            <p>Łączna wielkość: {formattedTotalSize}</p>
          </div>
        )}
        <div className="flex justify-between mb-4">
          <button
            onClick={uploadAllFiles}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-200"
            disabled={
              files.length === 0 || files.every((f) => f.status === "uploaded")
            }
          >
            Upload All Files
          </button>
          <button
            onClick={clearAllFiles}
            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-200"
            disabled={files.length === 0}
          >
            Clear All
          </button>
        </div>
        {files.map((fileInfo, index) => (
          <div
            key={index}
            className={`mb-4 p-4 border border-gray-200 rounded transition-all duration-300 ${
              highlightedFiles[index] ? "bg-yellow-100" : ""
            } ${fileInfo.status === "uploaded" ? "opacity-50" : ""}`}
          >
            <div className="flex justify-between items-center mb-2">
              <p className="font-semibold">{fileInfo.file.name}</p>
              <button
                onClick={() => removeFile(index)}
                className="bg-gray-200 text-gray-700 py-1 px-2 rounded hover:bg-gray-300 transition duration-200"
                disabled={fileInfo.status === "uploading"}
              >
                Remove
              </button>
            </div>
            <p className="text-sm text-gray-600">
              Size: {(fileInfo.file.size / (1024 * 1024)).toFixed(2)} MB
            </p>
            <p className="text-sm text-gray-600">Status: {fileInfo.status}</p>
            {uploadStatus[index] && (
              <p className="text-sm text-gray-600">{uploadStatus[index]}</p>
            )}
            {uploadProgress[index] > 0 && (
              <div className="mt-2 w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${uploadProgress[index]}%` }}
                ></div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiFileUploadSPA;
