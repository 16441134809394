import React from "react";
import FileUploadSPA from "./FileUploadSPA";

function App() {
  return (
    <div className="App">
      <FileUploadSPA />
    </div>
  );
}

export default App;
